<template>
  <div class="card pt-5 pb-5">
    <validation-observer ref="registerForm">
      <b-form class="">
        <div class="col-md-10 col-sm-12 m-auto">
          <div class="row">
            <div class="col-12">
              <h3 class="mb-2">
                {{ $t('Business profile') }}
              </h3>
            </div>
            <div class="col-12 d-flex mb-2">
              <div class="profile-img" @click="$refs.myFile.click()">
                <input ref="myFile" type="file" class="hidden" @change="onFileChange" />
                <BImgLazy
                  v-if="formData.profileThumbnail"
                  class="profile-img"
                  :src="
                    formData.profileThumbnail + (isPreview ? '' : '?' + Math.floor(Math.random()))
                  "
                  alt="profile image"
                />
                <div v-else class="profile-img no-img" alt="">+</div>
              </div>
              <div
                class="d-flex ml-2 mt-auto mb-auto cursor-pointer"
                @click="$refs.myFile.click(f)"
              >
                <p class="mb-0">
                  {{
                    !formData.profileThumbnail
                      ? $t('Please add your business logo')
                      : $t('Edit logo')
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <!-- username -->
              <b-form-group
                :label="$t('Business representative') + ' *'"
                label-for="representative"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Business representative')"
                  rules="required"
                >
                  <b-form-input
                    id="representative"
                    v-model="formData.name"
                    name="representative"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Business representative')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group :label="$t('Business name') + ' *'" label-for="business_name">
                <validation-provider #default="{ errors }" name="business name" rules="required">
                  <b-form-input
                    id="business_name"
                    v-model="formData.business_name"
                    name="business_name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Business name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group :label="$t('Phone') + ' *'" label-for="phone">
                <validation-provider #default="{ errors }" :name="$t('Phone')" rules="required">
                  <b-form-input
                    id="phone"
                    v-model="formData.phone"
                    name="phone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="+34*******"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group :label="$t('Website URL')" label-for="website">
                <b-form-input
                  id="website"
                  v-model="formData.website_url"
                  placeholder="https://www.toddl.co/example"
                />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label-for="location" :label="$t('Business location')">
                <v-select
                  id="location"
                  v-model="formData.main_location_id"
                  :placeholder="$t('Location')"
                  label="name"
                  :options="locationsList"
                  :reduce="option => option.id"
                >
                  <template #list-footer>
                    <li class="text-center d-flex">
                      <b-link
                        class="cursor-pointer"
                        style="margin: auto; padding: 0.3rem; width: 100%"
                        :to="{ name: 'create-location' }"
                      >
                        {{ $t('Add new location') }}
                      </b-link>
                    </li>
                  </template>
                </v-select>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group :label="$t('Facebook link')" label-for="facebook">
                <b-form-input
                  id="facebook"
                  v-model="formData.facebook_url"
                  placeholder="https://www.facebook.co/example"
                />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group :label="$t('Instagram link')" label-for="instagram">
                <b-form-input
                  id="instagram"
                  v-model="formData.instagram_url"
                  placeholder="https://www.instagram.co/example"
                />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label-for="category" :label="$t('Categories')">
                <v-select
                  id="category"
                  v-model="formData.categories"
                  multiple
                  :placeholder="$t('Categories')"
                  label="name"
                  :loading="categoriesLoader"
                  :options="categoriesList"
                  :reduce="option => option.id"
                />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label-for="age" :label="$t('Age Range')">
                <validation-provider #default="{ errors }" :name="$t('Age Range')" rules="required">
                  <vue-slider
                    id="age"
                    v-model="ageRange"
                    class="mb-2 mt-3"
                    :min="0"
                    :max="19"
                    :marks="marks"
                    :included="true"
                    :hide-label="true"
                    :process-style="{ backgroundColor: '#6BC4C5' }"
                    :tooltip-style="{
                      backgroundColor: '#F8F8F8',
                      borderColor: '#F8F8F8',
                      color: '#0E1A1A',
                      fontWeight: '700',
                    }"
                    :tooltip="'always'"
                    :state="errors.length > 0 ? false : null"
                  >
                    <template #tooltip="{ index, value }">
                      <div v-if="value === 0.5" class="tooltip-slider">6 {{ $t('months') }}</div>
                      <div v-if="value < 0.5" class="tooltip-slider">
                        {{ $t('New born') }}
                      </div>
                      <div v-if="value >= 1 && value < 19" class="tooltip-slider">
                        {{ value }}
                        {{ value === 1 ? $t('year') : $t('years') }}
                      </div>
                      <div v-if="value == 19" class="tooltip-slider">
                        {{ $t('Adults') }}
                      </div>
                    </template>
                  </vue-slider>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group label-for="about" :label="$t('Description')">
                <b-form-textarea
                  id="about"
                  v-model="formData.about"
                  :placeholder="$t('Description')"
                  rows="14"
                />
              </b-form-group>
              <small style="margin-bottom: 2.5em; display: block">{{
                $t('profile_desc_help')
              }}</small>

              <DropZone
                :label="$t('Add photos (Min 1 photo Max 8 photos)')"
                :files-thumbnail="formData.filesThumbnail"
                :files-i-ds="formData.files"
                @data-changed="changeFilesIDs"
              />
            </div>

            <div class="col-6">
              <b-form-group :label="$t('Email')" label-for="email" class="position-relative">
                <span class="input-custom-title">
                  <b-link :to="{ name: 'change-email' }">
                    {{ $t('change') }}
                  </b-link>
                </span>
                <b-form-input
                  id="email"
                  v-model="formData.email"
                  readonly
                  name="email"
                  placeholder="Stephanie@toddl.co"
                />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group :label="$t('Password')" label-for="password" class="position-relative">
                <span class="input-custom-title">
                  <b-link :to="{ name: 'change-password' }">
                    {{ $t('change') }}
                  </b-link>
                </span>
                <b-form-input
                  id="password"
                  name="password"
                  readonly
                  value="············"
                  style="background-color: #efefef"
                />
              </b-form-group>
            </div>
            <div class="col-12 mt-3">
              <b-button
                class="mr-2 footer-btn"
                variant="primary"
                type="submit"
                :disabled="submitLoader"
                @click.prevent="validationForm()"
              >
                {{ $t('Save') }}
              </b-button>

              <b-button
                class="footer-btn"
                :to="{ name: 'my-profile' }"
                type="submit"
                variant="outline-primary"
                :class="formData.published === 0 ? 'ml-2' : ''"
                :disabled="submitLoader"
                @click.prevent
              >
                {{ $t('Cancel') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DropZone from '@/components/DropZone'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BImgLazy,
  BLink,
  BFormTextarea,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { required, email } from '@/libs/validations'
import vSelect from 'vue-select'
import ActivityService from '@/services/ActivityService'
import MyProfileService from '@/services/MyProfileService'
import LocationService from '@/services/LocationService'
import VueSlider from 'vue-slider-component'

export default {
  name: 'EditProfile',
  components: {
    vSelect,
    VueSlider,
    DropZone,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BImgLazy,
    BLink,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BTabs,
    BTab,
  },
  data() {
    return {
      ActivityService: new ActivityService(),
      MyProfileService: new MyProfileService(),
      LocationService: new LocationService(),
      formData: {
        name: '',
        business_name: '',
        email: '',
        phone: '',
        main_location_id: '',
        categories: [],
        about: undefined,
        min_age: '',
        max_age: '',
        facebook_url: '',
        instagram_url: '',
        website_url: '',
        profile_url: '',
        profile_image: '',
        profileThumbnail: '',
        files: [],
        filesThumbnail: [],
      },
      marks: [0, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
      locationsList: [],
      categoriesList: [],
      submitLoader: false,
      categoriesLoader: true,
      required,
      email,
      isPreview: false,
    }
  },
  computed: {
    ageRange: {
      get() {
        return [this.formData.min_age, this.formData.max_age]
      },
      set(newName) {
        this.formData.min_age = newName[0]
        this.formData.max_age = newName[1]
        return [this.formData.min_age, this.formData.max_age]
      },
    },
  },
  mounted() {
    this.getCategoriesList()
    this.getLocationsList()
    this.setInitialValues()
  },
  methods: {
    setInitialValues() {
      this.formData = {
        name: this.$store.state.auth.user.name,
        business_name: this.$store.state.auth.user.business_name,
        email: this.$store.state.auth.user.email,
        phone: this.$store.state.auth.user.phone,
        main_location_id:
          this.$store.state.auth.user.main_location_id ??
          this.$store.state.auth.user.location[0]?.id,
        about: this.$store.state.auth.user.about,
        min_age: this.$store.state.auth.user.min_age ?? 4,
        max_age: this.$store.state.auth.user.max_age ?? 9,
        facebook_url: this.$store.state.auth.user.facebook_url,
        instagram_url: this.$store.state.auth.user.instagram_url,
        website_url: this.$store.state.auth.user.website_url,
        profile_url: this.$store.state.auth.user.profile_url,
        profile_image: null,
        profileThumbnail: this.$store.state.auth.user.profile_image,
        categories: [],
        files: [],
        filesThumbnail: [],
      }
      this.$store.state.auth.user.media.forEach(image => {
        this.formData.files.push(image.id)
        this.formData.filesThumbnail.push({ id: image.id, original_url: image.original_url })
      })
      this.$store.state.auth.user.categories.forEach(category => {
        this.formData.categories.push(category.id)
      })
    },
    getCategoriesList() {
      this.categoriesLoader = true
      this.ActivityService.getCategoriesList().then(res => {
        this.categoriesLoader = false
        this.categoriesList = res.data
      })
    },
    getLocationsList() {
      this.LocationService.index().then(res => {
        this.locationsList = res.data
      })
    },
    changeFilesIDs(e) {
      this.formData.files = e
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files
      this.formData.profile_image = e.target.files[0]
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      const reader = new FileReader()
      reader.onload = e => {
        this.formData.profileThumbnail = e.target.result
        this.isPreview = true
      }
      reader.readAsDataURL(file)
    },
    async validationForm() {
      try {
        const success = await this.$refs.registerForm.validate()

        if (success) {
          this.submitLoader = true
          const keys = Object.keys(this.formData)
          const formData = new FormData()

          for (const key of keys) {
            if (Array.isArray(this.formData[key])) {
              for (const val of this.formData[key]) {
                formData.append(key + '[]', val)
              }
              continue
            }
            if (this.formData[key] !== null && typeof this.formData[key] !== 'undefined') {
              formData.append(key, this.formData[key])
            }
          }
          formData.delete('profileThumbnail[]')
          formData.delete('filesThumbnail')
          formData.append('_method', 'put')

          const res = await this.MyProfileService.updateProfileDetails(formData)

          this.$posthog.capture('Edit profile', {
            $set: {
              name: res.name,
              provider: res.business_name,
            },
          })

          await this.$store.dispatch('auth/fetchUserData')

          this.submitLoader = false
          this.$router.push({ name: 'my-profile' })
        }
      } catch (error) {
        console.error(error)
        this.submitLoader = false
      }
    },
  },
}
</script>

<style scoped>
.footer-btn {
  width: 20%;
}

.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  object-fit: cover;
  cursor: pointer;
}

.no-img {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  background: #c1c3c5;
  color: #fff;
}

.input-custom-title {
  position: absolute;
  bottom: -20px;
  right: 0;
  /*text-decoration: underline;*/
  cursor: pointer;
}

#password.form-control[readonly] {
  background-color: transparent;
}

.tab-content {
  margin-top: 0.5em !important;
}

@media (max-width: 600px) {
  .footer-btn {
    width: 40%;
  }
}
</style>
